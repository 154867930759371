@import './assets/fontawesome/css/all.min.css';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';
@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

$header-height: 80px;
$light-grey-color: #e4e3ee;
$dark-bg-color: #000426;

body {
    background-color: $dark-bg-color;
    font-family: 'Roboto', sans-serif;
    // font-family: sans-serif, Verdana, Geneva, Tahoma;
    margin: 0;
    overflow-x: hidden;
    overflow-wrap: anywhere;
    padding: 0;
    &.ys-modal-open {
        overflow: hidden;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
    hyphens: none;
}
button {
    &:focus {
        outline: 0;
    }
}

.particles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 148px;
    z-index: -1;
}

// Those styles that are not possible to handle with bootstrap

.ys-mobile-nav {
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 2147483647;
    // overflow: auto;
    ul {
        overflow-y: auto;
        margin-top: 50px;
        li > a {
            font-size: 24px;
            text-decoration: none;
        }
    }
}

.ys-nav {
    background: linear-gradient(
        180deg,
        rgba(0, 4, 38, 1) 50%,
        rgba(40, 45, 97, 0.7)
    );
    height: $header-height;
    transition: top 500ms ease;
    top: 0;
    z-index: 1;
    ul > li > a {
        font-size: 15px;
        line-height: 20px;
        text-decoration: none;
    }
}

.ys-section-title {
    font-weight: lighter;
    font-size: clamp(64px, 10vw, 112px);
    line-height: clamp(64px + 6, 10vw, 112px);
}

.ys-hero-section {
    background-color: $dark-bg-color;
    margin-top: $header-height;
    padding-left: 3vh;
    padding-right: 3vh;
    padding-bottom: 1rem;
    .ys-lead-text {
        font-size: clamp(14px, 4vw, 32px);
    }
    .ya-action-btn {
        border-radius: 27px;
        font-size: 14px;
        height: 54px;
    }
    @include media-breakpoint-up(md) {
        min-height: 100vh;
        margin-top: 0;
        padding-left: 20vw;
        padding-top: calc(1.5 * #{$header-height});
        .ya-action-btn {
            border-radius: 31px;
            font-size: 18px;
            height: 62px;
        }
        > a {
            margin-right: 17vw;
        }
    }
    @include media-breakpoint-up(lg) {
        .ys-lead-text {
            max-width: 70vw;
        }
    }
}

.ys-testimonials-section {
    background: linear-gradient(180deg, rgba(40, 45, 97, 0.7), #000426 50%);
    color: #f9f9ff;
    padding-bottom: 76px;
    padding-top: $header-height;
    @include media-breakpoint-up(md) {
        padding-top: 200px;
    }
    .carousel-inner {
        border: solid 1px #e4e3ee;
        border-radius: 10px;
        padding: 15px;
        max-width: 400px;
        margin: auto;
    }
    .info {
        color: $light-grey-color;
        letter-spacing: 2px;
        p:last-child {
            border-top: solid 1px #e4e3ee;
            line-height: 24px;
            margin-top: 16px !important;
            padding-top: 16px;
        }
    }
}

.ys-aboutus-section {
    margin-top: $header-height;
    min-height: 100vh;
    padding-left: 3vh;
    padding-right: 3vh;
    padding-bottom: 1rem;
    .ys-aboutus-commet {
        position: absolute;
        right: 0;
        top: 100vh;
        margin-top: -220px;
    }
    @include media-breakpoint-down(md) {
        min-height: auto;
        .ys-aboutus-commet {
            display: none;
        }
    }
    .ys-paragraph-text {
        width: 240px;
        line-height: 30px;
    }
}

.ys-team-section + .ys-section-title {
    line-height: 20px i !important;
}

.ys-team-section + span {
    line-height: 20px i !important;
}

.ys-portfolio-section {
    .ys-portfolio-quartermoon {
        position: absolute;
        right: 0;
        top: 0;
    }
    @include media-breakpoint-down(md) {
        min-height: auto;
        .ys-portfolio-quartermoon {
            display: none;
        }
    }
    background: linear-gradient(180deg, #000426 50%, rgba(40, 45, 97, 0.7));
    padding-top: $header-height;
    padding-left: 3vh;
    padding-right: 3vh;
    padding-bottom: 1rem;
    position: relative;
    min-height: 100vh;
    line-height: 90px;
    .image-gallery-content,
    .image-gallery {
        width: 100%;
    }
    .image-gallery-thumbnails-wrapper.left,
    .image-gallery-thumbnails-wrapper.right {
        width: 45%;
        margin-left: 20px;
        padding: 3rem;
        justify-content: right;
    }
    .image-gallery-slide-wrapper.right {
        padding: 3rem;
        width: 50%;
    }
    @include media-breakpoint-down(lg) {
        .image-gallery-slide-wrapper.right,
        .image-gallery-thumbnails-wrapper.right {
            width: 100%;
        }
    }
    .image-gallery-image img {
        max-height: 200px;
    }
    .thumbnail-item {
        opacity: 0.5;
        line-height: 30px;
        padding-bottom: 10px;

        border-bottom: 1px solid #9e9bbd;
        white-space: initial;
        margin-left: 20px;

        .ys-section-title {
            line-height: 20px;
            white-space: normal;
            white-space: initial;
        }
    }
    .image-gallery-thumbnail {
        width: 100%;
        display: block;
    }
    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:hover,
    .image-gallery-thumbnail:focus {
        border: 0;
        p {
            color: #ff5c00 !important;
        }
        .thumbnail-item {
            &:before {
                content: '';
                display: inline-block;
                height: 1.5em;
                width: 1.5em;
                background-image: url('./assets/images/bullet.svg');
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: 0.5em;
                position: fixed;
                margin-left: 0px;
            }
        }
    }
}

.ys-team-section {
    // margin-top: $header-height;
    padding-left: 3vh;
    padding-right: 3vh;
    padding-bottom: 1rem;
    background-color: white;
    min-height: 100vh;
    line-height: 90px;

    .image-gallery-slide .image-gallery-image {
        border-radius: 50% 0px 50% 50%;
    }
    .image-gallery-thumbnails {
        margin-bottom: 26px;
        margin-left: 12px;
    }
    .image-gallery-thumbnail + .image-gallery-thumbnail {
        margin-left: 12px;
    }
    .ys-team-title {
        text-align: center;
        flex-direction: column !important;
        line-height: 20px !important;
        .ys-section-title {
            line-height: 30px;
            white-space: normal;
        }
    }
    @include media-breakpoint-down(md) {
        min-height: auto;
        .ys-team-svg {
            display: none;
        }
        .ys-team-title {
            width: 100%;
            text-align: center;
        }
        .ys-section-title {
            width: 100% !important;
        }
    }
}

.ys-contact-section {
    background-color: white;
    padding-top: 60px;
    padding-bottom: 220px;
    @include media-breakpoint-up(md) {
        padding-top: 117px;
        padding-bottom: 85px;
    }
}

.ys-directions {
    background-color: $light-grey-color;
    display: grid;
    padding: 40px clamp(40px, 10vw, 80px);
    h3 {
        font-size: 24px;
        font-weight: lighter;
        letter-spacing: -1px;
    }
    h2 {
        font-size: 14px;
    }
    p {
        font-size: 15px;
        font-weight: lighter;
    }
    @include media-breakpoint-up(md) {
        h3 {
            font-size: 32px;
        }
    }
}

.image-gallery-slide .image-gallery-description {
    background: transparent !important;
    bottom: 70px;
    color: #fff;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;
}

.image-gallery-thumbnail-image {
    border-radius: 50%;
}

.image-gallery-thumbnail {
    &.active,
    &:hover,
    &:focus {
        border-radius: 50%;
        @media (max-width: $ig-small-screen) {
            border-radius: 50%;
        }
    }
}

// Tech Stack Section
.ys-techstack-section {
    padding-top: $header-height;

    .tab-container {
        margin: 42px 0;
        width: 100%;
        button {
            border: solid 1px #e4e3ee !important;
            box-sizing: border-box;
            height: 48px;
            flex: 1;
            font-size: 12px;
            padding: 0 0 !important;
            white-space: nowrap;
            &:nth-child(2) {
                border-left: none !important;
                border-right: none !important;
            }
            span {
                letter-spacing: 2.5px;
            }
        }
        @include media-breakpoint-up(md) {
            margin: 44px 0;
            width: fit-content;
            button {
                font-size: 14px;
                padding: 0 60px !important;
            }
        }
    }
    .stack-group {
        column-gap: 15vw;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 15vh;
        > .stack-item {
            align-items: center;
            border-radius: 50%;
            border: solid 3px white;
            display: flex;
            height: 58px;
            justify-content: center;
            position: relative;
            width: 58px;
            &.active {
                opacity: 1;
                transition: all 1s;
                -webkit-transition: all 1s;
            }
            &.inactive {
                opacity: 0.05;
                transition: all 1s;
                -webkit-transition: all 1s;
            }
            > span {
                font-weight: lighter;
                position: absolute;
                top: 100%;
                margin-top: 10px;
                white-space: nowrap;
                letter-spacing: 2.5px;
            }
        }
    }
    > a {
        margin-top: 68px;
    }
}
