.icon--sm {
    font-size: 9.6; /* var(--font-size-sm); */
    line-height: 1 !important;
}
.icon--md {
    font-size: 12px; /* var(--font-size-md);*/
    line-height: 1 !important;
}
.icon--lg {
    font-size: 18px; /* var(--font-size-lg); */
    line-height: 0.95 !important;
}
.icon--xl {
    font-size: 30px; /* var(--font-size-xl); */
    line-height: 1 !important;
}
