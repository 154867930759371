.social {
    width: auto;
    height: auto;
    position: fixed;
    z-index: 10;
}
.social > ul {
    margin: 0;
    padding: 0;
}

.social--right {
    right: 30px;
}
.social--left {
    left: 30px;
}
.social--top {
    top: 30px;
}
.social--bottom {
    bottom: 30px;
}

.vertical {
    display: flex;
    flex-direction: column;
}
.vertical-reverse {
    display: flex;
    flex-direction: column-reverse;
}
.vertical > a {
    margin: 10px 0;
}
.vertical-reverse > a {
    margin: 10px 0;
}

.horizontal {
    display: flex;
    flex-direction: row;
}
.horizontal-reverse {
    display: flex;
    flex-direction: row-reverse;
}
.horizontal > a {
    margin: 0 10px;
}
.horizontal-reverse > a {
    margin: 0 10px;
}
